<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            <template
              v-if="title !== ''"
            >
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="title" />
              <!--eslint-enable-->
            </template>
            <template v-else>
              {{ $t('Link de pedido de consentimento') }}
            </template>
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >

          <b-row>
            <b-col md="12">
              <p>{{ $t('Para pedir consentimento, envie um email com o link a baixo.') }}</p>
              <p>{{ $t('Se pretender enviar por SMS, gere um link curto.') }}</p>
              <p>{{ $t('Este link é válido até:') }} <b>{{ infoConsentimento.sw266s08.substring(0, 16) }}</b></p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('Link')"
              >
                <b-form-input
                  placeholder=""
                  autocomplete="off"
                  :value="infoConsentimento.sw266s03"
                  :readonly="true"
                  :disabled="false"
                  @click="copyToClipboard(infoConsentimento.sw266s03)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="!infoConsentimento.sw266s04"
          >
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="mt-1"
                @click.stop.prevent="linkShortConsentimento"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Gerar link curto') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="infoConsentimento.sw266s04"
          >
            <b-col md="12">
              <b-form-group
                :label="$t('Link curto para SMS')"
              >
                <b-form-input
                  placeholder=""
                  autocomplete="off"
                  :value="infoConsentimento.sw266s04"
                  :readonly="true"
                  :disabled="false"
                  @click="copyToClipboard(infoConsentimento.sw266s04)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="infoConsentimento.sw266s04"
          >
            <b-col md="12">
              <b-alert
                variant="primary"
                show
                class="mt-1 mb-1"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="AlertCircleIcon"
                  />
                  <span class="ml-25">{{ $t('Os dois links servem o mesmo efeito.') }}</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row
            v-if="errorGenerateLinkShort===true"
          >
            <b-col md="12">
              <b-alert
                variant="danger"
                show
                class="mt-1"
              >
                <div class="alert-body">
                  <span>{{ $t('Serviço temporariamente indisponível. Por favor utilizar o serviço da bitly.com de forma manual.') }} </span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

        </b-container>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BRow, BCol, BContainer, BFormInput, BButton, BAlert, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VueClipboard from 'vue-clipboard2'
import Vue from 'vue'
import btoa from 'btoa'
import crmLeadsEventsModule from '@store-modules/crm/leads/listview/events'
import { mixFormGeral } from '../../mixins'

Vue.use(VueClipboard)

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BContainer,
    BFormInput,
    BButton,
    BAlert,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    close: {
      type: Function,
      required: false,
      default: () => {},
    },
    infoConsentimento: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      errorGenerateLinkShort: false,
    }
  },
  methods: {
    closeModal() {
      if (typeof this.close === 'function') {
        this.close()
      }
    },
    async linkShortConsentimento() {
      const self = this

      try {
        self.$root.$emit('app::loading', true)
        await this.$store.dispatch('crm_leads_events/generateLinkShortConsentimento', {
          sw266s01: btoa(this.infoConsentimento.sw266s01),
        }).then(async url => {
          this.infoConsentimento.sw266s04 = url
          await self.$root.$emit('app::loading', false)
          self.errorGenerateLinkShort = false
        }).catch(error => {
          self.$root.$emit('app::loading', false)
          self.showMsgErrorRequest(error)
          self.errorGenerateLinkShort = true
        })
      } catch (err) {
      //
      }
    },
    copyToClipboard(value) {
      this.$copyText(value).then(() => {
        this.showMsgSuccess({ message: this.$t('Copiado') })
      }).catch(() => {
        this.showMsgError({ message: this.$t('Problema ao copiar o link') })
      })
    },
  },
  setup() {
    const CRM_LEADS_MODULE_NAME_EVENTS = 'crm_leads_events'

    if (!store.hasModule(CRM_LEADS_MODULE_NAME_EVENTS)) {
      store.registerModule(CRM_LEADS_MODULE_NAME_EVENTS, crmLeadsEventsModule)
    }
  },
}
</script>
